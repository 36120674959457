import { Suspense, lazy, useEffect } from "react";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useMatch,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Loading from "./components/Loading";
import PrivateRoute from "./components/PrivateRoute";

const Home = lazy(() => import("./containers/Home"));
const Login = lazy(() => import("./containers/Login"));

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route path="/" element={<ContainerLayout />}>
            <Route
              path="home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route exact path="login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

function ContainerLayout() {
  const navigate = useNavigate();
  const match = useMatch("/");

  useEffect(() => {
    const token =
      localStorage.USER && JSON.parse(localStorage.USER)?.access_token;
    if (!token) navigate("/login");
    else {
      if (match) {
        navigate("/home");
      }
    }
  }, [match, navigate]);

  return (
    <div
    // className="flex-col justify-between h-screen"
    // style={{ background: "red" }}
    >
      <Outlet />
      {/* <DefaultFooter /> */}
    </div>
  );
}

function NotFound() {
  return <div>Không tìm thấy trang.</div>;
}

export default App;
